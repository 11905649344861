import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/Seo';
import StructuredData from '../components/structured-data'
import parseHtml from '../components/blocks/parser'

function HomePage({ data }) {
  const { page } = data

  const post = {
    ...page,
    slug: '',
  }

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="webPage" post={post} />
      <div className="entry">{parseHtml(page.contentRaw)}</div>
    </Layout>
  )
}

HomePage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default HomePage

export const query = graphql`
  {
    page: wpPage(slug: { eq: "home" }) {
      title
      date
      modified
      chatDepartment
      contentRaw
      seo {
        ...postSeoFields
      }
    }
  }
`
